<template>
  <div>
    <el-form
      style="margin-top: 50px"
      label-position="top"
      label-width="180px"
      ref="addForm"
      :model="addFormDatas"
      class="formClass"
    >
      <el-form-item class="items" style="width: 100%" label="租户系统名称">
        <el-input
          style="width: 200px"
          type="input"
          placeholder="请输入"
          v-model="addFormDatas.tenantSystemName"
        ></el-input>
      </el-form-item>

      <el-form-item class="items" label="pc端-菜单左上角的 logo (1张)">
        <div class="tips">建议尺寸 180px * 50px</div>
        <uploadimgs
          @input="changeFileList"
          :fileNameLists="fileNameLists"
          :limit="1"
        ></uploadimgs>
      </el-form-item>
      <el-form-item class="items" label="pc端-登录页 logo (1张)">
        <div class="tips">建议尺寸 210px * 72px</div>
        <uploadimgs
          @input="changeFileList2"
          :fileNameLists="fileNameLists2"
          :limit="1"
        ></uploadimgs>
      </el-form-item>
      <!-- <el-form-item class="items" label="pc端-登录页背景图(1张)">
        <uploadimgs
          @input="changeFileList3"
          :fileNameLists="fileNameLists3"
          :limit="1"
        ></uploadimgs>
      </el-form-item> -->
      <el-form-item class="items" label="会员端-首页logo (1张)">
        <div class="tips">建议尺寸 160px * 45px</div>
        <uploadimgs
          @input="changeFileList4"
          :fileNameLists="fileNameLists4"
          :limit="1"
        ></uploadimgs>
      </el-form-item>
    </el-form>

    <div class="saveBtns">
      <el-button class="bgcMainColor btnColor" @click="addLogo">保存</el-button>
    </div>
  </div>
</template>
<script>
import uploadimgs from "@/components/upLoadImgs.vue";
export default {
  inject: ["reload"],
  components: {
    uploadimgs,
  },
  data() {
    return {
      fileNameLists: [],
      fileNameLists2: [],
      fileNameLists3: [],
      fileNameLists4: [],
      addFormDatas: {
        tenantSystemName: "",
      },
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.logoInfo();
  },
  activated() {},
  destroyed() {},
  methods: {
    changeFileList(array) {
      this.fileNameLists = array;
    },
    changeFileList2(array) {
      this.fileNameLists2 = array;
    },
    changeFileList3(array) {
      this.fileNameLists3 = array;
    },
    changeFileList4(array) {
      this.fileNameLists4 = array;
    },
    // 获取
    logoInfo() {
      this.$API.getSystemVI().then((res) => {
        let datas = res.data.result || "";
        console.log("datas", datas);

        this.addFormDatas.tenantSystemName = datas.tenantSystemName;
        // 租户logo (左上角)
        if (datas.tenantLogoUrl) {
          this.fileNameLists = [
            {
              name: datas.tenantLogo || "",
              url: datas.tenantLogoUrl || "",
            },
          ];
        }
        // 租户登陆页logo
        if (datas.tenantAdminLoginLogoUrl) {
          this.fileNameLists2 = [
            {
              name: datas.tenantAdminLoginLogo || "",
              url: datas.tenantAdminLoginLogoUrl || "",
            },
          ];
        }
        // pc-登录页背景图
        if (datas.tenantAdminLoginBgpicUrl) {
          this.fileNameLists3 = [
            {
              name: datas.tenantAdminLoginBgpic || "",
              url: datas.tenantAdminLoginBgpicUrl || "",
            },
          ];
        }
        // 会员端首页logo
        if (datas.tenantMobileLogoUrl) {
          this.fileNameLists4 = [
            {
              name: datas.tenantMobileLogo || "",
              url: datas.tenantMobileLogoUrl || "",
            },
          ];
        }
        // tenantAdminLoginBgpic  租户管理端登陆页背景图 名称
        // tenantAdminLoginBgpicUrl  租户管理端登陆页背景图Url

        // tenantAdminLoginLogo  租户登陆页logo 名称
        // tenantAdminLoginLogoUrl  租户登陆页logo Url

        // tenantLogo  租户logo 名称
        // tenantLogoUrl  租户logo Url

        // tenantSystemName  租户系统名称
      });

      //   this.$API.logoInfo().then((res) => {
      //     let rs = res.data.result || "";
      //     if (rs) {
      //       let obj = {
      //         name: rs.fileName,
      //         url: rs.url,
      //       };
      //       this.fileNameLists = [obj];
      //     }
      //   });
    },
    // 保存
    addLogo() {
      console.log("fileNameLists", this.fileNameLists);
      if (this.fileNameLists.length < 1) {
        this.$message.error("请上传左上角logo");
        return false;
      }
      if (this.fileNameLists2.length < 1) {
        this.$message.error("请上传登录logo");
        return false;
      }
      if (!this.addFormDatas.tenantSystemName) {
        this.$message.error("请填写系统名称");
        return false;
      }

      let params1 = {
        tenantAdminLoginBgpic:
          this.fileNameLists3.length > 0 ? this.fileNameLists3[0].name : "",
        tenantAdminLoginLogo: this.fileNameLists2[0].name,
        tenantLogo: this.fileNameLists[0].name,
        tenantMobileLogo:
          this.fileNameLists4.length > 0 ? this.fileNameLists4[0].name : "",
        tenantSystemName: this.addFormDatas.tenantSystemName,
      };
      params1.sign = this.Tools.getSign(params1);

      this.$API.updateSystemVI(params1).then((res) => {
        this.$message.success(res.data.message);
      });

      //   this.$API
      //     .addLogo({
      //       fileName: this.fileNameLists[0].name,
      //     })
      //     .then((res) => {
      //       this.$message.success(res.data.message);
      //       this.logoInfo();
      //     });
    },
  },
};
</script>
<style scoped lang='scss'>
.saveBtns {
  width: 200px;
  text-align: center;
  margin-top: 50px;
}

.bgcMainColor {
  width: 100px;
}

.formClass {
  display: flex;
  flex-wrap: wrap;
  width: 700px;

  .items {
    width: 50%;
  }

  .tips {
    // line-height: 24px;
  }

  /deep/ .el-form-item__label {
    font-size: 16px;
    font-weight: bold;
    padding: 0;
  }
}
</style>